import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import Layout from '../components/Layout'

// Zoom image system, necessary modules (kinda)
// rehype-react to embed components in Markdown
import rehypeReact from 'rehype-react'
// a "simple" convenience wrapper
import ZoomImage from '../components/ZoomImage'
// Component to hide code
import Hidden from '../components/Hidden'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'zoom-image': ZoomImage,
    'hidden': Hidden,
  }
}).Compiler;

class About extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return(
            <Layout location={this.props.location} title={siteTitle}>

                <SEO
                    title="About"
                    keywords={[`about`, `blog`, `portfolio`, `3d`, `graphics`]}
                />

                <h2
                    style={{
                        marginTop: 0,
                        color: `#df6c75`,
                    }}
                >
                </h2>

                <Image
                fixed={data.illustration.childImageSharp.fixed}
                style={{
                    marginRight: rhythm(1 / 2),
                    marginBottom: 0,
                    minWidth: 200,
                }}
                imgStyle={{
                    borderRadius: `15%`,
                }}
                />

                <div>{renderAst(data.markdownRemark.htmlAst)}</div>

            </Layout>
        )

    }
}

// Export the class About as the end product.
export default About

// Create a query to get an image
export const aboutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        url
        twitterHandle
      }
    }
    illustration: file(absolutePath: { regex: "/avatar.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 200, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    markdownRemark(frontmatter: { tags: { eq: "about" } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        tags
        title
      }
    }
  }
`

